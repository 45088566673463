import { render, staticRenderFns } from "./FeaturedDetail.vue?vue&type=template&id=bf0752ea&scoped=true"
import script from "./FeaturedDetail.vue?vue&type=script&lang=js"
export * from "./FeaturedDetail.vue?vue&type=script&lang=js"
import style0 from "./FeaturedDetail.vue?vue&type=style&index=0&id=bf0752ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf0752ea",
  null
  
)

export default component.exports