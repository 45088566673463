<template>
  <div class="detail-row-class">
    <div class="row">
      <div class="col-6 my-auto">
        <iframe class="wistia_embed"
                name="wistia_embed"
                :src="wistiaUrl"
                title="Wistia video player"
                allowtransparency="true"
                frameborder="0"
                scrolling="no"
                width="100%"
                height="100%"
                allowfullscreen
                mozallowfullscreen
                webkitallowfullscreen
                oallowfullscreen
                msallowfullscreen>
        </iframe>
      </div>
      <div class="col-6 my-auto">
        <p class="small"><b>Title:</b> {{ rowData.media_title }}</p>
        <p class="small"><b>Type:</b> {{ rowData.media_type }}</p>
        <p class="small"><b>Length:</b> {{ rowData.media_length |
          formatTime }}</p>
        <p class="small"><b>Created:</b> {{ created }}</p>
        <p class="small" v-if="rowData.added">
          <b>Added: </b> {{ added }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import FilterMixin from '@/mixins/FilterMixin';
  import moment from 'moment';

  export default {
    name   : 'MediaDetail',
    mixins : [
      FilterMixin,
    ],
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
      rowIndex : {
        type : Number,
      },
    },
    data() {
      return {
        created : moment(this.rowData.created)
          .format('MMM DD, YYYY, h:mm:ss A'),
        added : (this.rowData.added) ?
          moment(this.rowData.added).format('MMM DD, YYYY, h:mm:ss A') : null,
        wistiaUrl : 'https://fast.wistia.net/embed/iframe/' +
          this.rowData.wistia_id,
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/featured/featured-detail";
</style>

